import React from 'react';

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer--container">
                    <h1 className="logo logo--footer">Dwain NORSA</h1>
                    <p>Conception et réalisation par Dwain NORSA.<br/>© Copyright 2023.</p>
                    <div className="footer__liens">
                        <a href="./assets/fichiers/cv_dwain_norsa.pdf" target="_blank">
                            <i className="fa-solid fa-download" />
                            Télécharger mon CV
                        </a>
                        <a href="#about">
                            <i className="fa-solid fa-arrow-up" />
                            Remonter aux compétences
                        </a>
                        <a href="mailto:dwain.mobile@gmail.com">
                            <i className="fa-solid fa-envelope" />
                            Contactez-moi
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;