import React, { useEffect } from 'react';

import personal from "../../assets/images/personal.jpg"

const Hero = () => {

    useEffect(() => {
        init();
    }, [])

    class TypeWriter {
        constructor(txtElement, words, wait) {
            this.txtElement = txtElement;
            this.words = words;
            this.txt = '';
            this.wordIndex = 0;
            this.wait = parseInt(wait);
            this.type();
            this.isDeleting = false;
        }
    
        type() {
            const current = this.wordIndex % this.words.length;
            const fullTxt = this.words[current];
            this.isDeleting ? (this.txt = fullTxt.substring(0, this.txt.length - 1)) : (this.txt = fullTxt.substring(0, this.txt.length + 1));
            this.txtElement.innerHTML = this.txt;
    
            // Initial Type Speed
            let typeSpeed = 150;
            if (this.isDeleting) typeSpeed /= 2;
    
            // If word is complete
            if (!this.isDeleting && this.txt === fullTxt) {
                typeSpeed = 1000; // Make pause at end
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.wordIndex++;
                typeSpeed = 500; // Pause before start typing
            }
            setTimeout(() => this.type(), typeSpeed);
        }
    }
    
    const wordsX = ["communicant.", "UI/UX designer.", "bienveillant.", "consciencieux.", "CSS lover."];
    
    function init() {
        const txtElement = document.querySelector('.typewrite');
        new TypeWriter(txtElement, wordsX, 500);
    }

    return (
        <>
            <section id="hero" className="hero">
                <div className="hero__container">
                    <div className="banner__content">
                        <div className="media-img">
                            <img src={personal} alt="Profil" />
                        </div>
                        <div className="media-body">
                            <h6>Bienvenue, je suis</h6>
                            <h2>Dwain NORSA</h2>
                            <h4>Développeur Full Stack, mais aussi <span className="typewrite" /><span className="text-cursor" /></h4>
                            <p>
                                Je suis un développeur passionné par le monde de l'informatique, des nouvelles technologies et de la domotique depuis plusieurs années. 
                                {/* Diplômé d'un master en communication en 2015, j'ai travaillé dans l'informatique plusieurs années pour un grand groupe d'assurances. C'est la passion qui m'a poussé à reprendre les études dans l'informatique pour obtenir un diplôme de développeur d'applications React. */}
                            </p>
                            <ul className="media-body--list">
                                <li><i className="fa-solid fa-cake-candles" />30 ans</li>
                                <li><i className="fa-solid fa-mobile-screen-button" />06 26 88 61 86</li>
                                <li><a href="mailto:dwain.mobile@gmail.com"><i className="fa-solid fa-envelope" />dwain.mobile@gmail.com</a></li>
                                <li><i className="fa-solid fa-location-pin" />Villeneuve-Loubet, Côte d'Azur</li>
                            </ul>
                            <a className="cv" href="./assets/fichiers/cv_dwain_norsa.pdf" target="_blank">
                                Télécharger mon CV
                            </a>
                        </div>
                    </div>
                </div>
                <a href="#about">
                    <div className="scroll-down" />
                </a>
            </section>
        </>
    );
};

export default Hero;