import React from 'react';

const Diplomes = () => {
    return (
        <>
            <section id="diplomes">
                <h1>Mes diplômes</h1>
                <div className="diplomes--container">
                    <div className="diplome">
                        <h3>Développeur concepteur logiciel</h3>
                        <i className="fa-solid fa-award" />
                        <div className="diplome--description">
                            <p className="diplome--niveau">Diplôme de niveau 6 (Bac&nbsp;+4)</p>
                            <p className="diplome--annee">2023</p>
                            <p className="diplome--recu">RNCP 35976</p>
                        </div>
                    </div>
                    <div className="diplome">
                        <h3>Master : communication des entreprises</h3>
                        <i className="fa-solid fa-award" />
                        <div className="diplome--description">
                            <p className="diplome--niveau">Master (Bac&nbsp;+5)</p>
                            <p className="diplome--annee">2015</p>
                            <p className="diplome--recu">Reçu avec mention</p>
                        </div>
                    </div>
                    <div className="diplome">
                        <h3>Licence : communication visuelle</h3>
                        <i className="fa-solid fa-award" />
                        <div className="diplome--description">
                            <p className="diplome--niveau">Licence (Bac&nbsp;+3)</p>
                            <p className="diplome--annee">2013</p>
                            <p className="diplome--recu">Reçu avec mention</p>
                        </div>
                    </div>
                    <div className="diplome">
                        <h3>Bac série scientifique<br /></h3>
                        <i className="fa-solid fa-award" />
                        <div className="diplome--description">
                            <p className="diplome--niveau">Baccalauréat</p>
                            <p className="diplome--annee">2010</p>
                            <p className="diplome--recu">Reçu avec mention</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Diplomes;