import React, { useContext, useEffect } from 'react';
import { DarkModeContext } from '../../context/darkModeContext';

const MainNav = () => {

  const { dispatch, darkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }

    let selectMainNav = select('.main-nav')
    let navbar = select('.navbar')
    if (selectMainNav) {
      let mainNavOffset = selectMainNav.offsetTop
      const mainNavFixed = () => {
        if ((mainNavOffset - window.scrollY) <= -400) {
          selectMainNav.classList.add('navbar_fixed')
          navbar.classList.add('li_fixed')
        } else {
          selectMainNav.classList.remove('navbar_fixed')
          navbar.classList.remove('li_fixed')
        }
      }
      window.addEventListener('load', mainNavFixed)
      onscroll(document, mainNavFixed)
    }
  }, [])

  const handleClick = () => {
    const checkbox = document.getElementById("menu-btn");
    if (checkbox.checked) checkbox.checked = false;
  }


  return (
    <>
      <nav className="main-nav">
        <div className="main-nav__container">
          <div className="logo-container">
            <a href="/" className="logo">DWAIN NORSA</a>
          </div>
          <div className="links-container">
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
            <ul className="navbar" onClick={handleClick}>
              <li><a className="nav-link home" href="#hero"><i className="fa-solid fa-house"></i></a></li>
              <li><a className="nav-link" href="#about">Qui suis-je ?</a></li>
              <li><a className="nav-link" href="#parcours">CV</a></li>
              <li><a className="nav-link" href="#diplomes">Diplômes</a></li>
              <li><a className="nav-link" href="#recommandations">Recommandations</a></li>
              <li><a className="nav-link" href="#portfolio">Portfolio</a></li>
              <li><a className="nav-link" href="mailto:dwain.mobile@gmail.com">Contact</a></li>
              <li><i 
                className={"nav-link fa-regular " + (darkMode? "fa-sun" : "fa-moon")} 
                onClick={() => dispatch({type:"TOGGLE"})}
              ></i></li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MainNav;