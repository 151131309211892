import React, { useEffect } from "react";

const Portfolio = () => {
  useEffect(() => {
    initProjects();
  }, []);

  const book = [
    {
      title: "Affiches",
      description: "Supports de communication affiches",
      link: "book_affiches.pdf",
      img: "affiches.jpg",
    },
    {
      title: "Cartes de visite",
      description: "Création de cartes",
      link: "book_cdv.pdf",
      img: "cdv.jpg",
    },
    {
      title: "Dépliant",
      description: "Création d'une plaquette triptique",
      link: "book_depliant.pdf",
      img: "depliant.jpg",
    },
    {
      title: "Logo",
      description: "Création identité visuelle",
      link: "book_logo.pdf",
      img: "logo.jpg",
    },
    {
      title: "Rapport annuel",
      description: "Création d'infographies chiffrées",
      link: "book_rapport_annuel.pdf",
      img: "rapport.jpg",
    },
    {
      title: "Slides",
      description: "Slides pour site internet",
      link: "book_slides.pdf",
      img: "slides.jpg",
    },
  ];
  const persoProjects = [
    {
        title: "Todo List",
        description:
          "Application Todo List créée en vanilla Javascript, avec stockage des datas en LocalStorage du navigateur. Utilisation du Neuromorphic design",
        img: "todo.jpg",
        pageLink: "./projets/todo-list",
    },
    {
        title: "Password generator",
        description:
          "Application permettant de créer un mot de passe sécurisé avec caractères spéciaux ou non et choix de la longueur. Le mot de passe est automatiquement copié.",
        pageLink: "./projets/password-generator",
        img: "password.jpg",
    },
    {
      title: "Générateur de Regex",
      description: "Toutes les expressions réguilières à portée de main ! Utilisation d'une API d'intelligence artificielle pour générer les réponses.",
      pageLink: "./projets/regex-generator",
      img: "regex.jpg",
    },
    {
      title: "Booki",
      description:
        "Booki est une petite entreprise proposant un outil de planification de vacances. Avant de valider définitivement le design, l’entreprise a décidé de réaliser un prototype. Je suis chargé de créer ce prototype en intégrant la maquette en HTML et CSS.",
      objectifs:
        "Découper et intégrer maquette - HTML sémantique et SEO - Media queries - Font Awesome - Flexbox - Reset CSS - VS Code - Extension Live Server",
      img: "booki.jpg",
      pageLink: "https://dwain06.github.io/P2-Booki/",
      ghLink: "https://github.com/Dwain06/P2-Booki",
    },
    {
      title: "Ohmyfood",
      description:
        "Ohmyfood! est une jeune startup qui voudrait s’imposer sur le marché de la restauration. L’objectif est de développer un prototype 100% mobile qui répertorie les menus de 4 restaurants gastronomiques.",
      objectifs:
        "Animations en CSS, Keyframes - Création loading spinner - Versionning GitHub - Convention BEM - Versions mobile puis tablette et desktop - Validité W3C - Compatibilité Chrome et Firefox",
      img: "ohmyfood.jpg",
      pageLink: "https://dwain06.github.io/ohmyfood/",
      ghLink: "https://github.com/Dwain06/ohmyfood",
    },
    {
      title: "GameOn",
      description:
        "GameOn, société spécialisée dans les concours de jeux, met en place un formulaire d’inscription. L'objectif est de refactoriser et finaliser le développement JavaScript de la modale et du formulaire.",
      objectifs:
        "Refactoriser code JavaScript - Jquery - TypeScript - Fork repository GitHub - Fix GitHub issues - Créer des Regex - Erreurs à la soumission d'un formulaire",
      img: "gameon.jpg",
      pageLink: undefined,
      ghLink: "https://github.com/Dwain06/GameOn",
    },
    {
      title: "FishEye",
      description:
        "FishEye permet aux photographes de présenter leurs travaux. Les objectifs : coder le site de zéro, accessible (lecteurs d'écran, navigation au clavier), alimenté par des données au format JSON et avec des Factory Method Pattern.",
      objectifs:
        "Page dynamique avec l'id dans l'url - Modale de contact - Incrémentation des likes - Tri des médias et affichage dans une lightbox - Programmation orientée objet - Accessibilité (clavier & balises ARIA) - Fonctions asynchrones - Données JSON - ESLint",
      img: "fisheye.jpg",
      pageLink: "https://dwain06.github.io/Front-End-Fisheye/",
      ghLink: "https://github.com/Dwain06/Front-End-Fisheye",
    },
    {
      title: "Les Petits Plats",
      description:
        "Les Petits Plats, éditeur de livres de cuisine a décidé de réaliser son site de recettes de cuisine. L'objectif est de travailler sur des recettes test sans image, pour développer un algorithme de recherche performant en Vanilla JavaScript.",
      objectifs:
        "Implémenter 4 champs de recherche et croiser les résultats - Créer 2 algorithmes différents - Evaluer les performances avec jsben.ch - Fiche d'investigation des fonctionnalités - Algorigrammes - Bootstrap",
      img: "lespetitsplats.jpg",
      pageLink: "https://dwain06.github.io/Les-Petits-Plats/",
      ghLink: "https://github.com/Dwain06/Les-Petits-Plats",
    },
  ];
  const OCProjects = [
    {
      title: "Kasa",
      description:
        "Kasa est un site de réservations de logements. Codé il y a 10 ans en ASP.NET, il doit être mis à jour. L'objectif est une refonte totale en React avec NodeJS côté Back-end.",
      objectifs:
        "Navigation URL et page 404 avec React Router - Création de composants réutilisables - Utilisation des Props - Récupération des données via URL - Création d'un collapse et d'une galerie",
      img: "kasa.jpg",
      pageLink: "https://dwain06.github.io/kasa-app/",
      ghLink: "https://github.com/Dwain06/kasa-app",
    },
    {
      title: "Billed",
      description:
        "Billed est une entreprise qui produit des solutions Saas. L'objectif est de mettre en place des tests sur l'application de notes de frais pour corriger et sécuriser le développement.",
      objectifs:
        "Installer un Back-end - Tester et déboguer l'application - Ecrire des tests unitaires et d'intégration - Jest testing library - Rédaction plan de tests End-to-end",
      img: "billed.jpg",
      pageLink: undefined,
      ghLink: "https://github.com/Dwain06/Billed-app-FR-Front",
    },
    {
      title: "SportSee",
      description:
        "SportSee, application de sport souhaite afficher un dashboard présentant les performances de l'utilisateur. L'objectif est de créer des graphiques avec la bibliothèque Recharts et des données d'une API.",
      objectifs:
        "Réaliser des calls http depuis une API - Création d'une classe de modélisation de données - Documenter le code avec la JSDOC - Intégrer des PropTypes pour les composants - Library Recharts et prise en main de la documentation",
      img: "sportsee.jpg",
      pageLink: undefined,
      ghLink: "https://github.com/Dwain06/P12-front-sportsee",
    },
    {
      title: "ArgentBank",
      description:
        "Création d'une application bancaire, ArgentBank, permettant aux clients de se connecter de manière sécurisée et de gérer leurs comptes et leur profil. Puis spécification des endpoints d’API nécessaires pour une 2e phase.",
      objectifs:
        "Authentication avec JWT Json Web Token - Gestion du state global avec Redux toolkit - Utilisation et création d'une documentation API avec Swagger - Protection des parties de l'appli avec React private routes",
      img: "argentbank.jpg",
      pageLink: undefined,
      ghLink: "https://github.com/Dwain06/P13-argentbank-front",
    },
    {
      title: "HRnet",
      description:
        "HRnet, une application web interne qui gère les dossiers des employés est ancienne et utilise jQuery, ce qui entraîne des bugs. L'objectif est de créer entièrement une nouvelle application avec React et un design au choix.",
      objectifs:
        "Gestion du state avec React context - Conversion des plugins JQuery en React - Création d'un composant sous forme de library npm - Création de tests unitaires avec Jest et React Testing Library - Tests de performances avec Google LightHouse",
      img: "hrnet.jpg",
      pageLink: "https://dwain06.github.io/P14-appli-hrnet/",
      ghLink: "https://github.com/Dwain06/P14-appli-hrnet",
    },
  ];

  function initProjects() {
    const divBook = document.querySelector(".portfolio__container--book");
    const divPersoProjects = document.querySelector(
      ".portfolio__container--perso"
    );
    const divOCProjects = document.querySelector(".portfolio__container--oc");

    book.forEach((el) => {
      divBook.innerHTML += `<a href="./assets/book/${el.link}" target="_blank">
                    <figure class="card-portfolio">
                        <img src="./assets/book/${el.img}" alt="${el.title}">
                            <figcaption>
                                <h3>${el.title}</h3>
                                <p>${el.description}</p>
                            </figcaption>
                    </figure>
                <a/>`;
    });

    persoProjects.forEach((el) => {
      divPersoProjects.innerHTML +=
      `
      <figure class="card-portfolio">
          <img src="./assets/projects/${el.img}" alt="${el.title}">
              <figcaption>
                  
                  <p>${el.description}</p>
                  ` +
                  (el.objectifs
                    ? `<p class="card--portfolio--objectifs">${el.objectifs}</p>` : "") + `
                  <div class="card-portfolio--links">
                  ` +
                  (el.pageLink
                    ? `<a href="${el.pageLink}" class="pageLink" target="_blank">Voir la démo</a>`
                    : "") + 
                  (el.ghLink
                  ? `
                  <a href="${el.ghLink}" class="ghLink" target="_blank">Voir le projet GitHub</a>`
                  : "") + 
                  `</div>
              </figcaption>
      </figure>
      `;
    });
    // persoProjects.forEach((el) => {
    //     divPersoProjects.innerHTML += `<a href="./projets/${el.link}" target="_blank">
    //             <figure class="card-portfolio">
    //                 <img src="./assets/projects/${el.img}" alt="${el.title}">
    //                     <figcaption>
    //                         <h3>${el.title}</h3>
    //                         <p>${el.description}</p>
    //                     </figcaption>
    //             </figure>
    //         <a/>`;
    // });

    OCProjects.forEach((el) => {
      divOCProjects.innerHTML +=
        `
          <figure class="card-portfolio">
              <img src="./assets/projects/${el.img}" alt="${el.title}">
                  <figcaption>
                      
                      <p>${el.description}</p>
                      <p class="card--portfolio--objectifs">${el.objectifs}</p>
                      <div class="card-portfolio--links">
                      ` +
                      (el.pageLink
                        ? `<a href="${el.pageLink}" class="pageLink" target="_blank">Voir la démo</a>`
                        : "") +
                      `
                      <a href="${el.ghLink}" class="ghLink" target="_blank">Voir le projet GitHub</a>
                      </div>
                  </figcaption>
          </figure>
        `;
    });
  }

  return (
    <>
      <section id="portfolio">
        <h1>Mes réalisations</h1>

        <div className="portfolio__container">
          <input type="checkbox" className="checkbox-oc" />
          <i></i>
          <h2>React/Node.js</h2>
          <div className="portfolio__container--oc"></div>
        </div>

        <div className="portfolio__container">
          <input type="checkbox" />
          <i></i>
          <h2>Vanilla JavaScript</h2>
          <div className="portfolio__container--perso"></div>
        </div>

        <div className="portfolio__container">
          <input type="checkbox" />
          <i></i>
          <h2>Infographie - Communication</h2>
          <div className="portfolio__container--book"></div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
