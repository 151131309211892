import React from 'react';

const Testimonials = () => {

    const lettres = [
        { title: "Hypee Groupe", description: "Agence de communication", img: "hypee.jpg", lettre: "Lettre_Hypee.pdf" },
        { title: "CHRU de Nancy", description: "Communication du centre hospitalier", img: "chru.jpg", lettre: "Lettre_CHRU.pdf" },
        { title: "CMCAS de Nice", description: "Comité d'entreprise groupe EDF", img: "cmcas.jpg", lettre: "Lettre_CMCAS.pdf" },
        { title: "ESN Nesis", description: "Pour le client PRO BTP", img: "nesis_probtp.jpg", lettre: "Lettre_NESIS.pdf" }
    ];
    
    const avis = [
        { title: "Projet 2", avis: "Un livrable propre et correctement structuré, pas d'éléments gérés de façon approximative, de bonnes pratiques générales.", evaluateur: "Evaluateur Stéphane L.", img: "booki.jpg" },
        { title: "Projet 3", avis: "La maquette est parfaitement respectée.", evaluateur: "Evaluateur Brice C.", img: "ohmyfood.jpg" },
        { title: "Projet 6", avis: "L'étudiant a effectué une belle présentation du projet réalisé.", evaluateur: "Evaluateur Mewena A.", img: "fisheye.jpg" },
        { title: "Projet 7", avis: "Travail très bien realisé, le code est commenté et indenté et aussi modulaire et réutilisable.", evaluateur: "Evaluateur Wilfried M.", img: "lespetitsplats.jpg" },
        { title: "Projet 9", avis: "Livrables au bon format avec du contenu très bien soigné, bonne compréhension du sujet et respect du cahier des charges.", evaluateur: "Evaluateur Ahmadou N.", img: "billed.jpg" },
        { title: "Projet 11", avis: "Projet bien structuré et initié avec Create React App avec une intégration bien conforme aux éléments de la maquette.", evaluateur: "Evaluateur Cheikh T.", img: "kasa.jpg" },
        // { title: "Projet 12", avis: "Le code fourni est très propre et documenté de façon précise. L'étudiant a bien séparé les composants, les pages ainsi que les services.", evaluateur: "Evaluateur Seynou N.", img: "sportsee.jpg" },
        { title: "Projet 13", avis: "Dwain a manifesté une très bonne compréhension du projet et a su fournir des éléments de réponse pertinents face aux questions posées.", evaluateur: "Evaluateur Abdelhadi Z.", img: "argentbank.jpg" },
    ];

    function checkbox() {
        document.querySelector(".checkbox-oc").checked = true;
    }

    return (
        <>
            <section id="recommandations">
                <h1>Ce qu'on pense de moi</h1>
                <h2>Lettres de recommandation</h2>
                <div className="reco__container--lettre">
                    {lettres.map((el, index) => (
                        <div className="card-reco lettre" key={index}>
                            <img src={"./assets/testimonials/" + el.img} alt={el.title} />
                            <h3>{el.title}</h3>
                            <p>{el.description}</p>
                            <a href={"./assets/fichiers/lettres_reco/" + el.lettre} target="_blank" rel="noopener noreferrer">
                                Voir la lettre de recommandation
                            </a>
                        </div>
                    ))}
                </div>
                {/* <h2>Les avis des évaluateurs OpenClassrooms</h2>
                <h3>Retrouvez la démo et le code des projets juste <a href="#portfolio" onClick={checkbox}> en dessous <i className="fa-solid fa-arrow-down"></i></a></h3>
                <div className="reco__container--avis">
                    {avis.map((el, index) => (
                        <div className="card-reco" key={index}>
                            <a href="#portfolio" onClick={checkbox}>
                                <img src={"./assets/testimonials/" + el.img} alt={el.title} />
                            </a>
                            <h3>{el.title}</h3>
                            <p className="card-reco__avis">"{el.avis}"</p>
                            <p>{el.evaluateur}</p>
                        </div>
                    ))}
                </div> */}
            </section>
        </>
    );
};

export default Testimonials;