import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useContext } from "react";
import Index from "./pages/index/Index";
import { DarkModeContext } from "./context/darkModeContext";

const App = () => {

    const { darkMode } = useContext(DarkModeContext);

    return (
        <div className={darkMode? "app dark" : "app"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
