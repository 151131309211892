import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DarkModeContextProvider } from './context/darkModeContext';
import "./styles/main.scss"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <DarkModeContextProvider>
    <App />
  </DarkModeContextProvider>
  // </React.StrictMode>
);