import React, { useEffect } from "react";

const About = () => {
    useEffect(() => {
        const devSkillsList = document.querySelector(".skills--list.dev ul");
        const commSkillsList = document.querySelector(".skills--list.comm ul");

        devSkills.forEach((el) => buildSkills(el, devSkillsList));
        commSkills.forEach((el) => buildSkills(el, commSkillsList));
    }, []);

    const devSkills = [
        { name: "HTML", img: "html.jpg", stars: 5 },
        { name: "JavaScript", img: "js.jpg", stars: 4 },
        { name: "React", img: "react.jpg", stars: 4 },
        { name: "Redux", img: "redux.jpg", stars: 4 },
        { name: "Next.js", img: "next.jpg", stars: 3 },
        { name: "Angular", img: "angular.jpg", stars: 3 },
        { name: "Node.js", img: "nodejs.jpg", stars: 3 },
        { name: "Java", img: "java.jpg", stars: 2 },
        { name: "Express", img: "express.jpg", stars: 3 },
        { name: "Spring Boot", img: "springboot.jpg", stars: 1 },
        { name: "Mongo db", img: "mongodb.jpg", stars: 3 },
        // { name: "Google Firebase", img: "firebase.jpg", stars: 2 },
        { name: "CSS", img: "css.jpg", stars: 5 },
        { name: "Sass", img: "sass.jpg", stars: 5 },
        { name: "Bootstrap", img: "bootstrap.jpg", stars: 3 },
        // { name: "TypeScript", img: "ts.jpg", stars: 2 },
    ];

    const commSkills = [
        { name: "Photoshop", img: "photoshop.jpg", stars: 5 },
        { name: "Indesign", img: "indesign.jpg", stars: 4 },
        { name: "Illustrator", img: "illustrator.jpg", stars: 3 },
        { name: "UI/UX design", img: "uiux.jpg", stars: 4 },
        { name: "Postman", img: "postman.jpg", stars: 3 },
        { name: "GitHub", img: "github.jpg", stars: 3 },
        { name: "GitLab", img: "gitlab.jpg", stars: 3 },
        { name: "Figma", img: "figma.jpg", stars: 3 },
        { name: "Jest", img: "jest.jpg", stars: 3 },
        { name: "Canva", img: "canva.jpg", stars: 4 },
        { name: "Anglais", img: "langue.jpg", stars: 3 },
        // { name: "Word", img: "word.jpg", stars: 5 },
        // { name: "Excel", img: "excel.jpg", stars: 3 },
        // { name: "PowerPoint", img: "powerpoint.jpg", stars: 4 },
        // { name: "Wordpress", img: "wordpress.jpg", stars: 4 },
    ];

    const buildSkills = (el, list) => {
        const li = document.createElement("li");
        li.innerHTML += `<img src="assets/skills/${el.img}" alt=${el.name}>${el.name}<br>`;
        for (let i = 0; i < el.stars; i++)
            li.innerHTML += `<i class="fa-solid fa-star"></i>`;
        list.appendChild(li);
    };

    return (
        <>
            <section id="about">
                <h1>Qui suis-je ?</h1>
                <div className="about__resume">
                    <div>
                        Diplômé d'un master en communication en 2015 et après avoir travaillé dans ce domaine plusieurs années, j'ai été consultant pour la Direction Informatique de PRO&nbsp;BTP pendant plus de 3 ans. Cette mission et ma passion pour le web m'ont poussé à reprendre des études pour valider mes connaissances et obtenir un diplôme de développeur d'applications. Je suis aujourd'hui développeur Full Stack chez Inov Team sur Java/ Angular.
                        <br />
                        <br />
                        Consciencieux, bienveillant et enthousiaste, ce sont les
                        qualités que l'on me donne. Je sais m’adapter à un
                        environnement spécifique et technique pour mener à bien
                        les missions qui me sont confiées.
                        <br />
                        <br />
                        {/* J'ai acquis les compétences suivantes par le biais
                        d'OpenClassrooms, acteur majeur de la formation
                        informatique diplômante&nbsp;:
                        <ul>
                            <li>
                                <i className="fa-solid fa-check"></i>Implémenter
                                des mockups en HTML, CSS et Sass&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>Créer des
                                applications web dynamiques avec JavaScript et
                                React&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>Gerer des
                                projets en mode Agile, utilisation de Kanban
                                Notion, création de User Stories et maquettes
                                Figma&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>Communiquer
                                avec une API, créer un back en Node.js et utiliser une bibliothèque npm
                                avec Recharts&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>Mettre en
                                œuvre des test unitaires et d’intégration avec
                                l'outil Jest&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>Résoudre
                                des problèmes techniques avec algorithmes&nbsp;;
                            </li>
                            <li>
                                <i className="fa-solid fa-check"></i>
                                Refactoriser du code Jquery, produire une
                                documentation technique et publier une librairie
                                npm.
                            </li>
                        </ul>
                        <br /> */}
                        Découvrez <a className="colored-link" href="#recommandations">ce qu'on pense de moi <i className="fa-solid fa-arrow-down"></i></a>
                    </div>
                </div>
                <div className="about__skills">
                    <div className="skills--container">
                        <div className="skills--title">
                            <h3>Stack</h3>
                        </div>
                        <div className="skills--list dev">
                            <ul>{/* Dev skills */}</ul>
                        </div>
                    </div>
                    <div className="skills--container">
                        <div className="skills--title">
                            <h3>Outils & comm'</h3>
                        </div>
                        <div className="skills--list comm">
                            <ul>{/* Communication skills */}</ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;
