import React from 'react';

// Import components
import About from '../../components/about/About';
import Diplomes from '../../components/diplomes/Diplomes';
import Footer from '../../components/footer/Footer';
import Hero from '../../components/hero/Hero';
import MainNav from '../../components/mainNav/MainNav';
import Parcours from '../../components/parcours/Parcours';
import Portfolio from '../../components/portfolio/Portfolio';
import Testimonials from '../../components/testimonials/Testimonials';

const Profil = () => {
    return (
        <>
            <MainNav />
            <Hero />
            <About />
            <Portfolio />
            <Parcours />
            <Diplomes />
            <Testimonials />
            <Footer />
        </>
    );
};

export default Profil;