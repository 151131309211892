import React from 'react';

const Parcours = () => {

    const experiences = [
        { date: "En 2023", icon: "icon fa fa-code", title: "Développeur Full Stack", description: "J'ai intégré en tant que consultant et développeur, l'ESN <span class='bold'>Inov&nbsp;Team</span>, où je code sur plusieurs technologies comme <span class='bold'>React/ Node.js</span> et <span class='bold'>Angular/ Java.</span>" },
        { date: "2022-23", icon: "icon fa fa-graduation-cap", title: "OpenClassrooms", description: "Reprise des études sur 14 mois pour l'obtention d'un diplôme de <span class='bold'>Développeur concepteur logiciel.</span>. Développement de projets en <span class='bold'>Javascript, React, Redux, Node.js & MongoDB.</span>" },
        { date: "2018-21", icon: "icon fa fa-computer", title: "Consultant en informatique", description: "Durant 3 ans et demi, au sein de la DDI du <span class='bold'>groupe PRO&nbsp;BTP</span>, développement informatique des maquettes de courriers, d'email et de SMS, à destination des <span class='bold'>3&nbsp;millions d'adhérents.</span>" },
        { date: "en 2018", icon: "icon fa fa-plug", title: "Chargé de communication", description: "Responsable de la communication des Activités Sociales du <span class='bold'>groupe EDF</span> sur le département des Alpes-Maritimes, <span class='bold'>auprès de plus de 8&nbsp;000 bénéficiaires.</span>" },
        { date: "2017-18", icon: "icon fa fa-flask", title: "Communication interne", description: "Gestion des actions de communication interne pour le site de Sophia-Antipolis du <span class='bold'>groupe Dow</span>, société multinationale qui emploie près de <span class='bold'>36&nbsp;000 personnes</span>." },
        { date: "en 2016", icon: "icon fa fa-circle-up", title: "Conseils en communication", description: "<span class='bold'>Création d'entreprise</span> dans le conseil et l'accompagnement. Formation aux techniques de communication auprès d'entrepreneurs et artisans." },
        { date: "en 2015", icon: "icon fa fa-hospital", title: "Communication et gestion de projet", description: "Accompagnement au changement de près de <span class='bold'>9&nbsp;000 personnels de soin</span> par des actions de communication dans un contexte hospitalier complexe, au <span class='bold'>CHRU de Nancy</span>, hôpital régional public." },
        { date: "en 2014", icon: "icon fa fa-volleyball", title: "Chargé de communication", description: "Participation à l'organisation de TIGALY, un événement sportif international à Lyon rassemblant <span class='bold'>1&nbsp;200 participants</span>, pour l'association sportive <span className='bold'>CARGO</span>." },
        { date: "en 2013", icon: "icon fa fa-lightbulb", title: "Chargé de communication", description: "Au sein d'une agence de communication, <span class='bold'>Hypee Groupe</span>, développement de l'image interne de la société et mise en place de stratégies marketing et supports de communication pour nos clients." },
    ];
    
    return (
        <>
            <section id="parcours">
                <h1>Mon parcours en bref</h1>
                <h2>Parcours détaillé : <a href="https://www.linkedin.com/in/dwainnorsa/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-linkedin"></i></a><a href="./assets/fichiers/cv_dwain_norsa.pdf" target="_blank"><i className="fa-solid fa-square"></i></a></h2>
                <div className="timeline">
                    {experiences.map((xp, index) => (
                        <div className={index % 2 === 0 ? "container left" : "container right"} key={index}>
                            <div className="date">{xp.date}</div>
                                <i className={xp.icon}></i>
                                <div className="content">
                                    <h3>{xp.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: xp.description }}></p>
                                </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
};

export default Parcours;